<template>
  <section class="h-screen bg-white">
    <div class="h-full">
      <div class="flex h-full items-center justify-center">
        <div class="w-2/12 bg-dark-gray h-full">
          <div class="h-16 flex flex-row items-end">
            <img :src="ImgTitle" alt="Sample image" class="w-1/2 ml-10" style="max-width: 126px" />
          </div>
          <admin-menu :menu="menu" :current-role="currentRole" class="ml-10 mt-20" />
        </div>

        <div class="w-10/12 h-full">
          <div class="flex flex-row justify-between items-center h-16 px-5">
            <admin-bread-crambs :crumbs="$route.meta.crumbs" />
            <div class="flex flex-row text-end">
              <div class="mr-3">
                <p class="text-orange font-bold">{{ userName }}</p>
                <p class="text-gray text-sm">{{ roleName }}</p>
              </div>
              <div class="cursor-pointer" @click="exit">
                <svg width="30" height="31" viewBox="0 0 30 31" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M18.75 10.6622V8.31842C18.75 7.69682 18.5031 7.10068 18.0635 6.66114C17.624 6.2216 17.0279 5.97467 16.4062 5.97467H5.15625C4.53465 5.97467 3.93851 6.2216 3.49897 6.66114C3.05943 7.10068 2.8125 7.69682 2.8125 8.31842V22.3809C2.8125 23.0025 3.05943 23.5987 3.49897 24.0382C3.93851 24.4777 4.53465 24.7247 5.15625 24.7247H16.4062C17.0279 24.7247 17.624 24.4777 18.0635 24.0382C18.5031 23.5987 18.75 23.0025 18.75 22.3809V20.0372"
                    stroke="#222222"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M22.5 10.6622L27.1875 15.3497L22.5 20.0372"
                    stroke="#222222"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M11.1914 15.3497H27.1875"
                    stroke="#222222"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
            </div>
          </div>
          <div class="px-5">
            <router-view />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup>
import ImgTitle from '@/assets/img/login/title.svg'
import AdminBreadCrambs from '@/components/AdminBreadCrambs.vue'
import AdminMenu from '@/components/AdminMenu.vue'
import router from '@/router'
import { computed } from 'vue'
import { useWorksheets } from '@/stores/worksheets'

const { clearStore } = useWorksheets()

const menu = [
  { id: 1, name: 'Новые анкеты', icon: 'users', path: '/worksheet', permissions: ['manager', 'admin'] },
  {
    id: 2,
    name: 'Обработанные анкеты',
    icon: 'people',
    path: '/history',
    permissions: ['manager', 'admin'],
  },
  {
    id: 3,
    name: 'Пользователи',
    icon: 'users',
    path: '/users',
    permissions: ['admin'],
  },
]

const userName = computed(() => localStorage.getItem('name'))

const roleName = computed(() => (localStorage.getItem('superuser') === 'admin' ? 'Администратор' : 'Менеджер'))
const currentRole = computed(() => localStorage.getItem('superuser'))
const exit = () => {
  clearStore()
  localStorage.setItem('token', '')
  router.push('/login')
}
</script>

<style>
.admin-select {
  --ms-font-size: 1rem;
  --ms-line-height: 1.375;
  --ms-bg: rgb(249 250 251 / var(--tw-bg-opacity));
  --ms-bg-disabled: #f3f4f6;
  --ms-border-color: rgb(209 213 219 / var(--tw-border-opacity));
  --ms-border-width: 1px;
  --ms-border-color-active: #d1d5db;
  --ms-border-width-active: 1px;
  --ms-radius: 0.5rem;
  --ms-py: 0.5rem;
  --ms-px: 0.875rem;
  --ms-ring-width: 0;
  --ms-ring-color: #351506;
  --ms-placeholder-color: #9ca3af;
  --ms-max-height: 10rem;

  --ms-spinner-color: #fe5000;
  --ms-caret-color: #999999;
  --ms-clear-color: #999999;
  --ms-clear-color-hover: #000000;

  --ms-tag-font-size: 0.875rem;
  --ms-tag-line-height: 1.25rem;
  --ms-tag-font-weight: 600;
  --ms-tag-bg: #fe5000;
  --ms-tag-bg-disabled: #9ca3af;
  --ms-tag-color: #ffffff;
  --ms-tag-color-disabled: #ffffff;
  --ms-tag-radius: 4px;
  --ms-tag-py: 0.125rem;
  --ms-tag-px: 0.5rem;
  --ms-tag-my: 0.25rem;
  --ms-tag-mx: 0.25rem;

  --ms-tag-remove-radius: 4px;
  --ms-tag-remove-py: 0.25rem;
  --ms-tag-remove-px: 0.25rem;
  --ms-tag-remove-my: 0rem;
  --ms-tag-remove-mx: 0.125rem;

  --ms-dropdown-bg: #ffffff;
  --ms-dropdown-border-color: #d1d5db;
  --ms-dropdown-border-width: 1px;
  --ms-dropdown-radius: 4px;

  --ms-group-label-py: 0.3rem;
  --ms-group-label-px: 0.75rem;
  --ms-group-label-line-height: 1.375;
  --ms-group-label-bg: #e5e7eb;
  --ms-group-label-color: #374151;
  --ms-group-label-bg-pointed: #d1d5db;
  --ms-group-label-color-pointed: #374151;
  --ms-group-label-bg-disabled: #f3f4f6;
  --ms-group-label-color-disabled: #d1d5db;
  --ms-group-label-bg-selected: #fe5000a8;
  --ms-group-label-color-selected: #ffffff;
  --ms-group-label-bg-selected-pointed: #fe5000;
  --ms-group-label-color-selected-pointed: #ffffff;
  --ms-group-label-bg-selected-disabled: #f18451;
  --ms-group-label-color-selected-disabled: #d1fae5;

  --ms-option-font-size: 1rem;
  --ms-option-line-height: 1.375;
  --ms-option-bg-pointed: #ffffff;
  --ms-option-color-pointed: #1f2937;
  --ms-option-bg-selected: #fe5000;
  --ms-option-color-selected: #ffffff;
  --ms-option-bg-disabled: #ffffff;
  --ms-option-color-disabled: #d1d5db;
  --ms-option-bg-selected-pointed: #fe5000;
  --ms-option-color-selected-pointed: #ffffff;
  --ms-option-bg-selected-disabled: #ffffff;
  --ms-option-color-selected-disabled: #d8e3ddf1;
  --ms-option-py: 0.5rem;
  --ms-option-px: 0.75rem;

  --ms-empty-color: #4b5563;
}
</style>
