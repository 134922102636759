import SurveyCheck from './SurveyCheck'
import SurveyInput from './SurveyInput'
import SurveyInputMask from './SurveyInputMask'
import SurveyInputDate from './SurveyInputDate'
import SurveySelect from './SurveySelect'
import SurveySwitch from './SurveySwitch'
import SurveyButtonAdd from './SurveyButtonAdd'
import SurveyButtonDelete from './SurveyButtonDelete'
import SurveyInputImage from './SurveyInputImage'
import SurveySubHeader from './SurveySubHeader'
import AdminInput from './AdminInput'
import AdminInputDate from './AdminInputDate'
import AdminInputMask from './AdminInputMask'
import AdminButtonIcon from './AdminButtonIcon'
import AdminSpinner from './AdminSpinner'
import AdminModal from './AdminModal'

export default [
  SurveyCheck,
  SurveyInput,
  SurveyInputDate,
  SurveySelect,
  SurveySwitch,
  SurveyButtonAdd,
  SurveyButtonDelete,
  SurveyInputImage,
  SurveySubHeader,
  SurveyInputMask,
  AdminInput,
  AdminInputDate,
  AdminInputMask,
  AdminButtonIcon,
  AdminSpinner,
  AdminModal,
]
