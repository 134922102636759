<template>
  <select
    class="form-select appearance-none text-sm text-black border rounded-lg w-full py-[0.32rem] px-3 min-h-[auto] focus:outline-none focus:shadow-outline w-full bg-transparent leading-[2.15] outline-none transition-all duration-200 ease-linear motion-reduce:transition-none"
    aria-label="Default select example"
    @change="updateInput"
    :value="modelValue"
  >
    <option v-for="item in listOptions" :key="item" :value="valueKey ? item[valueKey] : item">
      {{ labelKey ? item[labelKey] : item }}
    </option>
  </select>
</template>
<script>
export default {
  name: 'SurveySelect',
  props: {
    modelValue: [String, Number, Object],
    listOptions: Array,
    labelKey: String,
    valueKey: String,
  },
  methods: {
    updateInput(event) {
      this.$emit('update:modelValue', event.target.value)
    },
  },
}
</script>
<style></style>
