<template>
  <input
    :type="type"
    :inputmode="inputmode"
    class="appearance-none text-black border rounded-lg text-sm w-full py-[0.32rem] px-3 focus:outline-none focus:shadow-outline min-h-[auto] peer min-h-[auto] bg-transparent leading-[2.15] outline-none transition-all duration-200 ease-linear motion-reduce:transition-none"
    ref="refValue"
    :value="modelValue"
    @input="updateInput"
    :placeholder="placeholder"
    :v-maska="vMaska"
  />
</template>
<script>
export default {
  name: 'SurveyInputMask',
  props: {
    modelValue: [String, Number],
    placeholder: String,
    vMaska: String,
    inputmode: {
      type: String,
      default: 'text',
    },
    type: {
      type: String,
      default: 'text',
    },
  },
  methods: {
    updateInput(event) {
      this.$emit('update:modelValue', event.target.value)
    },
  },
}
</script>
<style></style>
