<template>
  <div>
    <div>
      <button
        type="button"
        class="btn text-white bg-red-600 hover:bg-red-700 font-medium text-sm text-center inline-flex items-center rounded mr-1"
        @click="deleteSmth"
      >
        <img class="btn__icon" src="../../assets/img/trash.svg" alt="delete" />
      </button>
      <span class="cursor-pointer text-red-600 ml-1" @click="deleteSmth"> Удалить </span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SurveyButtonDelete',
  props: ['index'],
  setup(props, context) {
    const deleteSmth = () => {
      context.emit('action', props.index)
    }
    return {
      deleteSmth,
    }
  },
}
</script>

<style scoped>
.btn {
  width: 34px;
  height: 34px;
  margin-left: auto;
  margin-right: auto;
}
.btn__icon {
  margin-left: auto;
  margin-right: auto;
}
</style>
