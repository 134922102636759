import { defineStore } from 'pinia'
import axiosApiInstance from '@/libries/axios'

import { JSONParse, JSONStringify } from 'json-with-bigint'
import { get } from 'lodash'

export const useWorksheets = defineStore('worksheets', {
  state: () => ({
    worksheets: null,
    divisions: null,
    filters: {
      division: null,
      status: ['new'],
      search: null,
    },
    count: 0,
    offset: 0,
    limit: 10,
  }),

  actions: {
    fetchWorksheets() {
      return new Promise((resolve, reject) => {
        const statusString = this.filters.status.reduce(function (prevVal, currVal, idx) {
          return idx == 0 ? `status=${currVal}&` : prevVal + `status=${currVal}&`
        }, '')

        const url = `${process.env.VUE_APP_BACK_URL}/worksheet?`
          .concat(`limit=${this.limit}&`)
          .concat(`offset=${this.offset}&`)
          .concat(`search=${this.filters.search || ''}&`)
          .concat(statusString || '')
          .concat(this.filters.division ? `divisions=${this.filters.division}` : '')

        const options = {
          method: 'GET',
          url: url,

          transformResponse: (data) => {
            return JSONParse(data)
          },
        }
        axiosApiInstance(options)
          .then((response) => {
            resolve(response?.data || [])
          })
          .catch((error) => {
            console.log(error)
            reject(error)
          })
      })
    },

    fetchDivisions() {
      return new Promise((resolve, reject) => {
        const options = {
          method: 'GET',
          url: `${process.env.VUE_APP_BACK_URL}/divisions`,
          transformResponse: (data) => {
            return JSONParse(data)
          },
        }
        axiosApiInstance(options)
          .then((response) => {
            resolve(response?.data?.divisions || [])
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    clearStore() {
      this.worksheets = null
      this.divisions = null
      this.filters = {
        division: null,
        status: ['new'],
        search: null,
      }
    },

    // Setters
    setWorksheets({ worksheets, rows }) {
      this.worksheets = worksheets
      this.count = rows
    },
    setFilters({ name, value }) {
      this.filters[name] = value
    },
    setDivisions(divisions) {
      this.divisions = divisions
    },
    getDivisions() {
      return this.divisions || []
    },
    setDivisionsWithPermissions(divisions) {
      if (this.getSuperUser() === 'admin') {
        this.setDivisions(divisions)
        return
      } else {
        const divisionsWithPermissions = divisions.filter((division) => {
          return this.getDivisionsFromLocalStorage().includes(division.id.toString())
        })

        this.setDivisions(divisionsWithPermissions)
        this.setFilters({
          name: 'division',
          value: this.getDivisions().length ? this.getDivisions()[0]?.id || 'none' : 'none',
        })
        return
      }
    },
    setOffset(val) {
      this.offset = val
    },
    setCount(val) {
      this.count = val
    },
    setLimit(val) {
      this.count = val
    },
    getSuperUser() {
      return localStorage.getItem('superuser')
    },
    getDivisionsFromLocalStorage() {
      return JSON.parse(localStorage.getItem('divisions'))
    },
  },
})
