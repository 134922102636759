<template>
  <div>
    <div>
      <button
        type="button"
        class="btn text-white bg-gray-800 hover:bg-gray-700 hover:rounded-full font-medium rounded-full text-sm text-center inline-flex items-center mr-1"
        @click="addSmth"
      >
        <img class="btn__icon" src="../../assets/img/+.svg" alt="+" />
      </button>
      <span class="cursor-pointer ml-1" @click="addSmth"> Добавить </span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SurveyButtonAdd',
  setup(props, context) {
    const addSmth = () => {
      context.emit('action')
    }
    return {
      addSmth,
    }
  },
}
</script>

<style scoped>
.btn {
  width: 34px;
  height: 34px;
  margin-left: auto;
  margin-right: auto;
}
.btn__icon {
  margin-left: auto;
  margin-right: auto;
}
</style>
