<template>
  <router-view />
</template>
<script setup></script>
<style>
@import url('https://fonts.googleapis.com/css?family=Montserrat');
@import '@vueform/multiselect/themes/default.css';

#app {
  font-family: 'Montserrat', sans-serif;
  background: linear-gradient(190.49deg, #333f48 4.76%, #000008 13.83%);
}

html,
body,
#app,
.main {
  height: 100%;
}

.main {
  display: grid;
  grid-template-rows: 100px 1fr auto;
}

.survey {
  border-radius: 30px 30px 0px 0px;
  padding: 32px 16px 0 16px;
  z-index: 2;
  position: relative;
}

.form-select {
  --ms-font-size: 1rem;
  --ms-line-height: 1.375;
  --ms-bg: transparent;
  --ms-bg-disabled: #f3f4f6;
  --ms-border-color: rgb(209 213 219 / var(--tw-border-opacity));
  --ms-border-width: 1px;
  --ms-border-color-active: #d1d5db;
  --ms-border-width-active: 1px;
  --ms-radius: 0.5rem;
  --ms-py: 0.5rem;
  --ms-px: 0.875rem;
  --ms-ring-width: 0;
  --ms-ring-color: #351506;
  --ms-placeholder-color: #9ca3af;
  --ms-max-height: 10rem;

  --ms-spinner-color: #fe5000;
  --ms-caret-color: #999999;
  --ms-clear-color: #999999;
  --ms-clear-color-hover: #000000;

  --ms-tag-font-size: 0.875rem;
  --ms-tag-line-height: 1.25rem;
  --ms-tag-font-weight: 600;
  --ms-tag-bg: #fe5000;
  --ms-tag-bg-disabled: #9ca3af;
  --ms-tag-color: #ffffff;
  --ms-tag-color-disabled: #ffffff;
  --ms-tag-radius: 4px;
  --ms-tag-py: 0.125rem;
  --ms-tag-px: 0.5rem;
  --ms-tag-my: 0.25rem;
  --ms-tag-mx: 0.25rem;

  --ms-tag-remove-radius: 4px;
  --ms-tag-remove-py: 0.25rem;
  --ms-tag-remove-px: 0.25rem;
  --ms-tag-remove-my: 0rem;
  --ms-tag-remove-mx: 0.125rem;

  --ms-dropdown-bg: #ffffff;
  --ms-dropdown-border-color: #d1d5db;
  --ms-dropdown-border-width: 1px;
  --ms-dropdown-radius: 4px;

  --ms-group-label-py: 0.3rem;
  --ms-group-label-px: 0.75rem;
  --ms-group-label-line-height: 1.375;
  --ms-group-label-bg: #e5e7eb;
  --ms-group-label-color: #374151;
  --ms-group-label-bg-pointed: #d1d5db;
  --ms-group-label-color-pointed: #374151;
  --ms-group-label-bg-disabled: #f3f4f6;
  --ms-group-label-color-disabled: #d1d5db;
  --ms-group-label-bg-selected: #fe5000a8;
  --ms-group-label-color-selected: #ffffff;
  --ms-group-label-bg-selected-pointed: #5f5a58;
  --ms-group-label-color-selected-pointed: #ffffff;
  --ms-group-label-bg-selected-disabled: #f18451;
  --ms-group-label-color-selected-disabled: #d1fae5;

  --ms-option-font-size: 1rem;
  --ms-option-line-height: 1.375;
  --ms-option-bg-pointed: #ffffff;
  --ms-option-color-pointed: #1f2937;
  --ms-option-bg-selected: #aea8a4;
  --ms-option-color-selected: #ffffff;
  --ms-option-bg-disabled: #ffffff;
  --ms-option-color-disabled: #d1d5db;
  --ms-option-bg-selected-pointed: #aba6a4;
  --ms-option-color-selected-pointed: #ffffff;
  --ms-option-bg-selected-disabled: #ffffff;
  --ms-option-color-selected-disabled: #d8e3ddf1;
  --ms-option-py: 0.5rem;
  --ms-option-px: 0.75rem;

  --ms-empty-color: #4b5563;
}
</style>
