<template>
    <div class="relative">
  <input
    :id="id"
    type="text"
    class="block px-2.5 pb-2.5 pt-2.5 w-full text-sm text-gray-900 border-gray-300 bg-transparent rounded-lg border-1  appearance-none focus:outline-none focus:ring-0 focus:border-gray-600 peer"
    ref="refValue"
    :value="modelValue"
    @input="updateInput"
    :placeholder="placeholder"
    :v-maska="vMaska"
  />
  <label
      :for="id"
      class="absolute text-sm text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-gray-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1"
    >
    {{ label }}
  </label>
</div>
</template>
<script>
export default {
  name: 'AdminInputMask',
  props: {
    modelValue: [String, Number],
    placeholder: String,
    vMaska: String,
    id: String,
    label: String,
  },
  methods: {
    updateInput(event) {
      this.$emit('update:modelValue', event.target.value)
    },
  },
}
</script>
<style></style>
