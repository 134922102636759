<template>
  <div class="flex justify-center items-center w-full" v-if="!image">
    <label
      :for="`dropzone-file-${index}`"
      class="passport flex flex-col justify-center items-center w-full h-64 bg-gray-50 rounded-lg border-2 border-gray-300 border-solid cursor-pointer hover:bg-gray-100"
    >
      <div class="flex flex-col justify-center items-center pt-5 pb-6">
        <div class="passport__icon-container bg-gray-800 rounded-full">
          <img class="passport__icon" src="../../assets/img/file_download.svg" alt="+" />
        </div>
        <p class="mt-2 text-sm"><span class="font-semibold">Нажмите</span> для загрузки документа</p>
      </div>
      <input
        :id="`dropzone-file-${index}`"
        type="file"
        class="hidden"
        @change.prevent="changeImage"
        accept="image/png, image/gif, image/jpeg"
      />
    </label>
  </div>
  <div v-else class="flex justify-center items-center w-full">
    <div
      class="photocopy flex flex-col justify-center items-center w-full h-64 bg-gray-50 rounded-lg border-2 border-gray-300 border-solid"
    >
      <img :src="image" class="photocopy__image" alt="Ох, что-то не так" />
      <div class="photocopy__delete bg-red-600" @click.prevent="removeImage">
        <img src="../../assets/img/trash.svg" alt="" />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'SurveyInputImage',
  props: ['image', 'index'],
  emits: ['change'],
  methods: {
    changeImage(event) {
      const files = event.target.files
      if (files.length !== 0) {
        const file = files[0]
        const reader = new FileReader()
        reader.onload = (e) => this.$emit('change', e.target.result, this.$props.index, file.name)
        reader.readAsDataURL(file)
      }
    },
    removeImage() {
      this.$emit('change', null, this.$props.index)
    },
  },
}
</script>
<style scoped>
.passport {
  height: 120px;
  border-radius: 10px;
}

.passport__icon-container {
  width: 37px;
  height: 37px;
  display: flex;
  align-items: center;
}

.passport__icon {
  margin-left: auto;
  margin-right: auto;
}

.photocopy {
  height: 120px;
  border-radius: 10px;
  position: relative;
  overflow: hidden;
}
.photocopy__image {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 120px;
  width: 100%;
  object-fit: cover;
  z-index: 15;
}
.photocopy__delete {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 34px;
  width: 34px;
  border-radius: 7px;
  top: 5px;
  right: 5px;
  z-index: 30;
  cursor: pointer;
}
</style>
