<template>
  <input
    type="date"
    placeholder="dd.MM.yyyy"
    class="form-control appearance-none text-black text-sm border rounded-lg w-full py-[0.32rem] px-3 min-h-[auto] focus:outline-none focus:shadow-outline w-full bg-transparent leading-[2.15] outline-none transition-all duration-200 ease-linear motion-reduce:transition-none"
    ref="refValue"
    :value="modelValue"
    @input="updateInput"
  />
</template>
<script>
export default {
  name: 'SurveyInputDate',
  props: {
    modelValue: [String, Number],
  },
  methods: {
    updateInput(event) {
      this.$emit('update:modelValue', event.target.value)
    },
  },
}
</script>
<style scoped>
input[type='date'] {
  display: block;

  /* Solution 1 */
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  min-height: 1.2em;

  /* Solution 2 */
  /* min-width: 96%; */
}

.form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  background-clip: padding-box;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
</style>
