<template>
  <div class="flex" :class="`flex-${layout}`">
    <div
      class="form-check flex"
      :class="layout === 'col' ? 'mt-2 flex-nowrap' : 'mr-2 flex-wrap'"
      v-for="(opt, key) in listOptions"
      :key="key"
    >
      <div>
        <input
          class="form-check-input appearance-none inline-block h-4 w-4 border border-gray-200 rounded-sm checked:bg-white checked:border-gray-200 focus:outline-none transition duration-200 align-top bg-no-repeat bg-center bg-contain bg-gray-200 float-left mr-2 cursor-pointer"
          :type="type"
          :value="valueKey ? opt[valueKey] : opt"
          @change="updateInput"
          v-model="value"
        />
      </div>
      <label class="form-check-label inline-block text-gray-800">
        {{ labelKey ? opt[labelKey] : opt }}
      </label>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue'

export default {
  name: 'SurveyCheck',
  props: {
    modelValue: [Array, String, Object, Boolean],
    listOptions: [Array],
    layout: {
      type: String,
      default: 'col',
    },
    type: {
      type: String,
      default: 'checkbox',
    },
    labelKey: String,
    valueKey: String,
  },

  setup(prop) {
    const value = ref([])

    value.value = Array.isArray(prop.modelValue) ? prop.modelValue : prop.modelValue
    return {
      value,
    }
  },
  methods: {
    updateInput(event) {
      this.$emit('update:modelValue', this.value)
    },
  },
}
</script>

<style scoped>
.form-check-input:checked[type='checkbox'],
.form-check-input:checked[type='radio'] {
  background-image: url('~@/assets/img/check.svg');
}
</style>
