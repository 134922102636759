<template>
  <div>
    <span class="mr-2 toggle-orange">Нет</span>
    <Toggle
      :value="value"
      :false-value="falseValue"
      :true-value="trueValue"
      :classes="{
        container: 'inline-block rounded-full outline-none',
        toggle:
          'flex w-11 h-6 rounded-full relative cursor-pointer transition items-center box-content border-2 text-xs leading-none',
        toggleOn: 'bg-orange-500 border-orange-500 justify-start text-white',
        toggleOff: 'bg-gray-200 border-gray-200 justify-end text-gray-700',
        toggleOnDisabled: 'bg-gray-300 border-gray-300 justify-start text-gray-400 cursor-not-allowed',
        toggleOffDisabled: 'bg-gray-200 border-gray-200 justify-end text-gray-400 cursor-not-allowed',
        handle: 'inline-block bg-white w-6 h-6 top-0 rounded-full absolute transition-all',
        handleOn: 'left-full transform -translate-x-full',
        handleOff: 'left-0',
        handleOnDisabled: 'bg-gray-100 left-full transform -translate-x-full',
        handleOffDisabled: 'bg-gray-100 left-0',
        label: 'text-center w-8 border-box whitespace-nowrap select-none',
      }"
      @change="updateInput"
    />
    <span class="ml-2">Да</span>
  </div>
</template>
<script>
import { ref } from 'vue'
import Toggle from '@vueform/toggle'

export default {
  name: 'SurveySwitch',
  components: {
    Toggle,
  },
  props: {
    modelValue: [Boolean, Object, String],
    labelKey: String,
    valueKey: String,
    falseValue: {
      type: String || Boolean,
      default: 'false',
    },
    trueValue: {
      type: String || Boolean,
      default: 'true',
    },
  },
  methods: {
    updateInput(event) {
      const booleanValue = event === this.trueValue
      this.$emit('update:modelValue', booleanValue)
      this.value = event
    },
  },
  setup(prop) {
    const value = ref(true)
    // eslint-disable-next-line vue/no-setup-props-destructure
    value.value = prop.valueKey ? prop.modelValue[prop.valueKey] : prop.modelValue
    return {
      value,
    }
  },
}
</script>
<style></style>
