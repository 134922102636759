<template>
  <div>
    <label class="text-base text-black font-semibold">
      {{ title }}
    </label>
  </div>
</template>
<script>
export default {
  name: 'SurveySubHeader',
  props: ['title'],
}
</script>
