import { createApp } from 'vue'
import App from './App.vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import { createPinia } from 'pinia'
import router from './router'
import './assets/css/index.css'
import components from '@/components/UI'
import Maska from 'maska'
// import datepickerminjs from '@/libries/datepicker.min.js'

const app = createApp(App)

components.forEach((component) => {
  app.component(component.name, component)
})

// TODO
// eslint-disable-next-line no-undef
BigInt.prototype.toJSON = function () {
  return this.toString()
}

app.use(router)
app.use(Maska)
// app.use(datepickerminjs)
app.use(createPinia())
app.use(VueAxios, axios)
app.mount('#app')
