<template>
  <div class="modal" v-if="show">
    <div class="modal__wrapper">
      <button type="button" class="btn-close modal__wrapper__close" aria-label="Close" @click="close()" />
      <div class="modal__wrapper__title">
        {{ title }}
      </div>
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: 'AdminModal',

  props: {
    show: { type: Boolean, default: false },
    title: String,
    approveText: String,
    closeText: String,
    disabled: Boolean,
  },

  setup(props, context) {
    const aproove = () => {
      context.emit('aproove')
    }
    const close = () => {
      context.emit('close')
    }
    return {
      aproove,
      close,
    }
  },
}
</script>

<style lang="scss" scoped>
.modal {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.3);
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;

  &__wrapper {
    background: white;
    border-radius: 6px;
    padding: 25px 72px 25px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    max-width: 700px;

    &__close {
      position: absolute;
      top: 12px;
      right: 12px;
    }

    &__title {
      font-weight: 500;
      font-size: 28px;
      line-height: 34px;
      text-align: center;
      color: #5e5873;
      margin-bottom: 14px;
    }

    &__button {
      width: 200px;
      max-width: 100%;
      margin-bottom: 14px;
      font-weight: 500;
      height: 45px;
      font-size: 16px;
      line-height: 20px;
      letter-spacing: 0.4px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
</style>
