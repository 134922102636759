<template>
  <div class="relative" data-te-input-wrapper-init>
    <input
      :id="id"
      :type="type"
      :inputmode="inputmode"
      class="appearance-none text-black text-sm border rounded-lg w-full py-[0.32rem] px-3 min-h-[auto] focus:outline-none focus:shadow-outline w-full bg-transparent leading-[2.15] outline-none transition-all duration-200 ease-linear motion-reduce:transition-none"
      ref="refValue"
      :value="modelValue"
      @input="updateInput"
      :placeholder="label"
      :min="min"
      :step="step"
    />
  </div>
</template>
<script>
export default {
  name: 'SurveyInput',
  //   v-model="fields[field].value"
  props: {
    modelValue: [String, Number],
    placeholder: String,
    type: {
      type: String,
      default: 'text',
    },
    inputmode: {
      type: String,
      default: 'text',
    },
    id: String,
    label: String,
    min: String,
    step: String,
  },
  methods: {
    updateInput(event) {
      this.$emit('update:modelValue', event.target.value)
    },
  },
}
</script>
<style>
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}
</style>
