import { createRouter, createWebHistory } from 'vue-router'
import AdminMain from '../views/AdminMain.vue'

const routes = [
  {
    path: '/',
    name: 'help',
    component: () => import('../views/SurveyMain.vue'),
    meta: {
      auth: 'full',
    },
  },
  {
    path: '/docs',
    name: 'docs',
    component: () => import('../views/SurveyDocs.vue'),
    meta: {
      auth: 'full',
    },
  },
  {
    path: '/result',
    name: 'result',
    component: () => import('../views/SurveyResult.vue'),
    meta: {
      auth: 'full',
    },
  },
  {
    path: '/worksheet',
    component: AdminMain,
    redirect: '/worksheet/list',
    children: [
      {
        path: 'list',
        name: 'list',
        component: () => import('../views/admin-list/AdminList.vue'),
        meta: {
          title: 'Новые анкеты',
          crumbs: [{ name: 'Новые анкеты' }],
        },
      },
      {
        path: 'view/:id',
        name: 'view-worksheet',
        component: () => import('../views/admin-worksheet/AdminWorksheet.vue'),
        meta: {
          title: 'Новая анкета',
          crumbs: [{ name: 'Новые анкеты', link: '/worksheet' }, { name: 'Новая анкета' }],
        },
      },
      {
        path: 'edit/:id',
        name: 'edit-worksheet',
        component: () => import('../views/admin-worksheet/AdminWorksheet.vue'),
        meta: {
          title: 'Новая анкета',
          crumbs: [{ name: 'Новые анкеты' }, { name: 'Новая анкета' }],
        },
      },
    ],
    meta: {},
  },
  {
    path: '/history',
    component: AdminMain,
    redirect: '/history/list',
    children: [
      {
        path: 'list',
        name: 'history',
        component: () => import('../views/admin-list/AdminList.vue'),
        meta: {
          title: 'Обработанные анкеты',
          crumbs: [{ name: 'Обработанные анкеты' }],
        },
      },
      {
        path: 'view/:id',
        name: 'view-history-worksheet',
        component: () => import('../views/admin-worksheet/AdminWorksheet.vue'),
        meta: {
          title: 'Анкета соискателя',
          crumbs: [{ name: 'Обработанные анкеты', link: '/history' }, { name: 'Анкета соискателя' }],
        },
      },
    ],
    meta: {},
  },
  {
    path: '/users',
    component: AdminMain,
    redirect: '/users/list',
    children: [
      {
        path: 'list',
        name: 'users',
        component: () => import('../views/admin-users/AdminUsers.vue'),
        meta: {
          title: 'Пользователи',
          crumbs: [{ name: 'Пользователи' }],
        },
      },
      // {
      //   path: 'new',
      //   name: 'new-users',
      //   component: () => import('../views/admin-users/AdminUserEdit.vue'),
      //   meta: {
      //     title: 'Новый пользователь',
      //     crumbs: [{ name: 'Пользователи', link: '/users' }, { name: 'Новый пользователь' }],
      //   },
      // },
      // {
      //   path: 'edit/:id',
      //   name: 'edit-users',
      //   component: () => import('../views/admin-users/AdminUserEdit.vue'),
      //   meta: {
      //     title: 'Редактирование пользователя',
      //     crumbs: [{ name: 'Пользователи', link: '/users' }, { name: 'Редактирование пользователя' }],
      //   },
      // },
    ],
    meta: {},
  },

  {
    path: '/login',
    name: 'login',
    component: () => import('../views/AdminLogin.vue'),
    meta: {
      auth: 'full',
    },
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes,
  linkActiveClass: 'active',
})

router.beforeEach(async (to, from) => {
  if (to.meta?.auth != 'full') {
    const canAccess = isLogin()
    if (!canAccess) return '/login'
  }
})

const isLogin = () => {
  return !!localStorage.getItem('token')
}

export default router
