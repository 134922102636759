import axios from 'axios'

let isAlreadyFetchingAccessToken = false
let subscribers = []

function onAccessTokenFetched(access_token) {
  subscribers = subscribers.filter(callback => callback(access_token))
}

function addSubscriber(callback) {
  subscribers.push(callback)
}

const axiosApiInstance = axios.create()

axiosApiInstance.interceptors.request.use(function (config) {
  let token = localStorage.getItem('token')
  config.headers['Authorization'] = 'bearer ' + token
  return config
})

axiosApiInstance.interceptors.response.use(
  response => response,
  error => {
    const { response, config } = error
    const originalRequest = config
    
    return Promise.reject(error)
  }
)

export default axiosApiInstance
