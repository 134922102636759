<template>
  <div class="flex flex-col">
    <div v-for="menuItem in menu" :key="menuItem.id" class="mb-3">
      <router-link
        class="text-gray hover:text-white flex flex-row py-3"
        v-if="menuItem.permissions.includes(currentRole) || menuItem.permissions.includes('all')"
        active-class="menu-active"
        :to="menuItem.path"
      >
        <component :is="componentIcon(menuItem.icon)" class="mr-2" color="white" />
        {{ menuItem.name }}
      </router-link>
    </div>
  </div>
</template>
<script setup>
import IconPeople from '@/components/icons/IconPeople.vue'
import IconUsers from '@/components/icons/IconUsers.vue'

const props = defineProps({
  menu: Array,
  currentRole: String,
})

const icons = {
  people: IconPeople,
  users: IconUsers,
}

const componentIcon = (icon) => {
  return icons[icon]
}
</script>
<style>
.menu-active {
  color: white !important;
  box-shadow: 0px 0px 0px 0px black, 4px 0px 0px 0px #fe5000;
}
</style>
