<template>
  <div>
    <div>
      <button
        type="button"
        class="btn text-white bg-stone-400 hover-bg-orange font-medium text-sm text-center inline-flex items-center rounded mr-1"
        @click="doSmth"
      >
        <img class="btn__icon" :src="image" alt="delete" style="width: 20px; height: 20px" />
      </button>
      <span class="cursor-pointer ml-1" @click="doSmth"> {{ text }} </span>
    </div>
  </div>
</template>

<script>
import trash from '@/assets/img/trash.svg'
import password from '@/assets/img/reset-password.svg'
import edit from '@/assets/img/edit.svg'

export default {
  name: 'AdminButtonIcon',
  props: ['text', 'icon'],
  emits: ['action'],
  setup(props, context) {
    const doSmth = () => {
      context.emit('action')
    }
    const image = props.icon === 'trash' ? trash : props.icon === 'password' ? password : edit
    return {
      doSmth,
      image,
    }
  },
}
</script>

<style scoped>
.btn {
  width: 34px;
  height: 34px;
  margin-left: auto;
  margin-right: auto;
}
.btn__icon {
  margin-left: auto;
  margin-right: auto;
}
.hover-bg-orange:hover {
  background-color: #fe5000;
}
</style>
